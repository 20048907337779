import React, { useMemo } from 'react';
import * as XLSX from 'xlsx';
import { CartItem, Order } from '../product/context/ProductContext.tsx';
import { getOptionLabel } from '../product/ProductType.ts';
import toast from 'react-hot-toast';

interface ExportOrdersButtonProps {
  filteredOrders: Order[];
}

const ExportOrdersButton: React.FC<ExportOrdersButtonProps> = ({
  filteredOrders,
}) => {
  const noOrders = useMemo(() => filteredOrders.length === 0, [filteredOrders]);
  const handleExport = () => {
    if (noOrders) {
      toast.error('No hay órdenes para exportar.');
      return;
    }

    try {
      const combinedData = filteredOrders.flatMap((order: Order) => {
        return order.cart.items.map((item: CartItem) => ({
          'ID de Orden': order.id,
          Cliente: order.user?.name || 'N/A',
          Fecha: new Date(order.date).toLocaleDateString(),
          'Inicio Recepción': new Date(order.startReceiveOrderTime)
            .toTimeString()
            .slice(0, 5),
          'Fin Recepción': new Date(order.endReceiveOrderTime)
            .toTimeString()
            .slice(0, 5),
          'Opción de Compra': getOptionLabel(item.purchaseOption),
          Cantidad: item.quantity,
          Producto: item.product.name,
          'Precio Parcial': item.partialPrice,
          'Precio Total': item.totalPrice,
        }));
      });

      // Crear una hoja de trabajo con los datos combinados
      const worksheet = XLSX.utils.json_to_sheet(combinedData);

      // Crear un libro de trabajo y agregar la hoja
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Órdenes y Carrito');

      // Generar el archivo y descargarlo
      XLSX.writeFile(workbook, 'orders_and_cart.xlsx');
    } catch (error) {
      toast.error('Error al generar el archivo Excel:', error);
    }
  };

  return (
    <button
      className="primary-button margin-top"
      onClick={handleExport}
      disabled={noOrders}
    >
      Exportar órdenes
    </button>
  );
};

export default ExportOrdersButton;
