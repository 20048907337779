import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Header from '../components/header/Header.tsx';
import { cities, ROUTE_NAME_OPTIONS, timeZone } from '../utils/constants.ts';
import useTextareaAutoHeight from '../utils/useTextareaAutoHeight.ts';
import './user-info-form.scss';

//Token ssh
// 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

interface UserInfo {
  name: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  description: string;
  startReceiveOrderTime: string;
  endReceiveOrderTime: string;
}

interface UserInfoFormProps {
  userInfo: UserInfo;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  children?: ReactNode;
}

const UserInfoForm = ({
  userInfo,
  handleInputChange,
  children,
}: UserInfoFormProps) => {
  const { textareaRef } = useTextareaAutoHeight(userInfo.description);
  const location = useLocation();

  const isShoppingCartScreen =
    location.pathname === `/${ROUTE_NAME_OPTIONS.SHOPPING_CART}`;

  const isUserInfoScreen =
    location.pathname === `/${ROUTE_NAME_OPTIONS.USER_INFO}`;

  const handleTimezone = (value: string | undefined) => {
    if (value) {
      const [start, end] = value.split('-');
      handleInputChange({
        target: {
          name: 'startReceiveOrderTime',
          value: start,
        },
      } as React.ChangeEvent<HTMLInputElement>);

      handleInputChange({
        target: {
          name: 'endReceiveOrderTime',
          value: end,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleSelectChange = (name: string, value: string) => {
    handleInputChange({
      target: {
        name: 'city',
        value: value,
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div>
      {location.pathname !== '/shopping-cart' && <Header />}

      <form className="user-data-form">
        <div>
          {isUserInfoScreen && (
            <h2 className="product-title">Actualiza tu informacion personal</h2>
          )}

          <div className="flex-row">
            <section className="flex-column">
              <h3 className="product-title">Información de Contacto</h3>
              <div className="flex-align-center">
                <label className="label">Nombre:</label>
                <input
                  required
                  type="text"
                  name="name"
                  placeholder="nombre completo..."
                  className="input"
                  disabled={isShoppingCartScreen}
                  value={userInfo.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex-align-center">
                <label className="label">Teléfono:</label>
                <input
                  required
                  type="tel"
                  name="phone"
                  placeholder="teléfono..."
                  className="input"
                  disabled={isShoppingCartScreen}
                  value={userInfo.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex-align-center">
                <label className="label">Email:</label>
                <input
                  required
                  type="email"
                  name="email"
                  placeholder="email..."
                  className="input"
                  value={userInfo.email}
                  disabled
                  onChange={handleInputChange}
                />
              </div>
            </section>

            <section className="flex-column">
              <h3 className="product-title">Información de Envío</h3>
              <div className="flex-align-center">
                <label className="label">Dirección:</label>
                <input
                  required
                  type="text"
                  name="address"
                  placeholder="dirección..."
                  className="input"
                  disabled={isShoppingCartScreen}
                  value={userInfo.address}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex-align-center margin-top-4">
                <label className="label">Ciudad:</label>
                <Select
                  className="timezone"
                  required
                  placeholder="Selecciona tu ciudad"
                  options={cities}
                  value={cities.find((city) => city.value === userInfo.city)} // Coincidir el valor seleccionado
                  onChange={(selectedOption) =>
                    handleSelectChange('city', selectedOption?.value || '')
                  }
                />
              </div>
            </section>

            {!isUserInfoScreen && (
              <>
                <section className="flex-column">
                  <h3 className="product-subtitle">
                    Horario recepcion de envios
                  </h3>
                  <div className="flex-align-center">
                    <label className="label">Horarios :</label>
                    <Select
                      className="timezone"
                      required
                      placeholder="Selecciona una franja horaria"
                      options={timeZone}
                      onChange={(selectedOption) =>
                        handleTimezone(selectedOption?.value || undefined)
                      }
                    />
                  </div>
                </section>

                <section className="flex-column">
                  <h3 className="product-title">Detalles</h3>
                  <div className="input-container">
                    <div className="flex">
                      <label className="label">Descripción:</label>
                      <textarea
                        name="description"
                        ref={textareaRef}
                        className="textarea"
                        placeholder="Descripción del pedido..."
                        disabled={isUserInfoScreen}
                        value={userInfo.description}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </section>
              </>
            )}
          </div>
          {children}
        </div>
      </form>
    </div>
  );
};

export default UserInfoForm;
