import React from 'react';
import './loading.scss';

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="spinner">
        <div className="spinner-circle" />
      </div>
      <p className="loading-text">Cargando...</p>
    </div>
  );
};

export default Loading;
