import React from 'react';
import './confirm-modal.scss';

interface ConfirmModalProps {
  action: () => void;
  cancel: () => void;
  bodyText?: string;
  confirmText?: string;
  body?: any;
}

const ConfirmModal = ({
  action,
  cancel,
  bodyText,
  confirmText,
  body,
}: ConfirmModalProps) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>{bodyText}</h3>
        {body && body}
        <div className="modal-buttons">
          <button className="cancel-button" onClick={() => cancel()}>
            Cancelar
          </button>
          <button
            className="confirm-button"
            onClick={() => {
              action();
            }}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
