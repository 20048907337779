import React, { CSSProperties, useEffect } from 'react';
import ProductList from '../product/product-list/ProductList.tsx';
import Login from './Login.tsx';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../user/context/UserContext.tsx';

const LoginScreen = () => {
  const isLogin = localStorage.getItem('token');

  const navigate = useNavigate();
  const { userInfo } = useUserContext();

  useEffect(() => {
    // Si el usuario está logueado, redirige a /products
    if (userInfo) {
      navigate(`/`);
    }
  }, [userInfo, navigate]);

  return (
    <div style={styles.container}>
      <div style={styles.background}>
        <img
          src="/rapi-fruti-logo.jpeg"
          alt="Logo"
          style={styles.backgroundImage}
        />
      </div>
      <div style={styles.loginContainer}>
        {isLogin ? <ProductList /> : <Login />}
      </div>
    </div>
  );
};

export default LoginScreen;

const styles: { [key: string]: CSSProperties } = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  loginContainer: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '300px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translateY(-50%)',
    top: '50%',
  },
  logo: {
    width: '100px',
    marginBottom: '20px',
  },
};
