import React from "react";
import "./sku-name.scss";

// Definir las props con tipos
interface SkuNameProps {
  name: string; // El nombre del SKU
  action: () => void; // Función que se ejecuta al hacer clic
  isSkuSelected: boolean; // Indica si el SKU está seleccionado
}

const SkuName: React.FC<SkuNameProps> = ({ name, action, isSkuSelected }) => {
  return (
    <button
      onClick={action}
      className={`button-sku ${isSkuSelected ? "selected" : ""}`}
    >
      <span className="sku-name">{name}</span>
    </button>
  );
};

export default SkuName;
