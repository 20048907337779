export interface Product {
  name: string;
  brand?: string;
  description: string;
  image: string;
  id: string;
  price: string;
  purchaseOptions: string | ProductOptionValues[];
  boxWeight: string;
}

export type ProductPayload = Omit<Product, 'id'>;

export interface ProductOption {
  value: ProductOptionValues;
  label: string;
}

export enum ProductOptionValues {
  HALF_CRATE = 'half_crate',
  FULL_CRATE = 'full_crate',
  UNIT = 'unit',
  KG = 'kg',
  PACK = 'pack',
}

// Las opciones de compra se definen usando el enum 'ProductOptionValues'
export const purchaseOptions = [
  { value: ProductOptionValues.FULL_CRATE, label: 'Cajon completo' },
  { value: ProductOptionValues.HALF_CRATE, label: '1/2 Cajon' },
  { value: ProductOptionValues.UNIT, label: 'Unidad' },
  { value: ProductOptionValues.KG, label: 'Kg' },
  { value: ProductOptionValues.PACK, label: 'Paquete' },
];

export const getOptionLabel = (value: ProductOptionValues) => {
  const option = purchaseOptions.find((opt) => opt.value === value);
  return option ? option.label : value;
};
