import React, { useEffect, useState } from 'react';
import UserInfoForm from './UserInfoForm.tsx';
import Button from '../components/button/Button.tsx';
import { HOST } from '../utils/constants.ts';
import toast from 'react-hot-toast';
import { useUserContext } from './context/UserContext.tsx';
import { useLoadingContext } from '../login/context/LoadingContext.tsx';

interface UserInfoProps {
  id: number;
  name: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  description: string;
  startReceiveOrderTime: string;
  endReceiveOrderTime: string;
}

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState<UserInfoProps>({
    id: 0,
    name: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    description: '',
    startReceiveOrderTime: '',
    endReceiveOrderTime: '',
  });

  const { setUserInfo: updateContextUserInfo } = useUserContext();
  const { showLoading, hideLoading, isLoading } = useLoadingContext();

  const savedToken = localStorage.getItem('token');

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      try {
        const parsedUserInfo = JSON.parse(storedUserInfo) as UserInfoProps;
        setUserInfo(parsedUserInfo);
      } catch (error) {
        console.error('Error al parsear userInfo:', error);
      }
    }
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const updateUserInfo = async () => {
    try {
      showLoading();
      const response = await fetch(`${HOST}/users/${userInfo.id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${savedToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userInfo),
      });

      if (response.ok) {
        const token = localStorage.getItem('token');
        const user = await response.json();
        toast.success('Información actualizada exitosamente');
        localStorage.setItem('userInfo', JSON.stringify(user));
        if (token !== null) updateContextUserInfo({ user, token });
      } else {
        toast.error('Error al actualizar la información');
      }
    } catch (error) {
      toast.error('Hubo un problema al actualizar la información');
    } finally {
      hideLoading();
    }
  };

  return (
    <div>
      {!isLoading && (
        <>
          <UserInfoForm
            userInfo={userInfo}
            handleInputChange={handleInputChange}
            children={
              <Button
                className="margin-top-30 primary-button "
                onClick={updateUserInfo}
              >
                Actualizar Información
              </Button>
            }
          />
        </>
      )}
    </div>
  );
};

export default UserInfo;
