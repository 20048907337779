import imageCompression from 'browser-image-compression';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdAttachFile } from 'react-icons/md';
import Select, { MultiValue } from 'react-select';
import Header from '../../components/header/Header.tsx';
import ConfirmModal from '../../components/modal/ConfirmModal.tsx';
import { HOST } from '../../utils/constants.ts';
import useTextareaAutoHeight from '../../utils/useTextareaAutoHeight.ts';
import { Product, ProductPayload, purchaseOptions } from '../ProductType.ts';
import './product-form.scss';
import { useProductContext } from '../context/ProductContext.tsx';
import { useLoadingContext } from '../../login/context/LoadingContext.tsx';

interface ProductOption {
  value: string;
  label: string;
}

const placeholderPicture =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9cSGzVkaZvJD5722MU5A-JJt_T5JMZzotcw&s';

export async function readFile(file: File): Promise<string> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
}

const initialPayload: {
  name: string;
  brand: string;
  description: string;
  image: string;
  price: string;
  purchaseOptions: string;
  boxWeight: string;
} = {
  name: '',
  brand: '',
  description: '',
  image: '',
  price: '',
  purchaseOptions: '',
  boxWeight: '',
};

const ProductForm = () => {
  const [productForm, setProductForm] =
    useState<ProductPayload>(initialPayload);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [blured, setBlured] = useState<Partial<Record<keyof Product, boolean>>>(
    {},
  );
  const [productSelected, setProductSelected] = useState<Product>();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [selectedPurchaseOptions, setSelectedPurchaseOptions] = useState<
    ProductOption[]
  >([]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { textareaRef } = useTextareaAutoHeight(productForm.description);
  const { addProduct, products, deleteProduct } = useProductContext();
  const { showLoading, hideLoading, isLoading } = useLoadingContext();

  // Mapea los productos a opciones compatibles con react-select
  const productOptions: ProductOption[] = products.map((product) => ({
    value: product.id,
    label: product.name,
  }));

  const handlePurchaseOptionsChange = (
    selectedOptions: MultiValue<{ value: string; label: string }>,
  ) => {
    setSelectedPurchaseOptions([...selectedOptions]);
  };

  const savedToken = localStorage.getItem('token');

  const cleanValues = () => {
    setProductSelected(undefined);
    setProductForm(initialPayload);
    setSelectedPurchaseOptions([]);
  };

  const handleChangeSelectedProduct = (
    purchaseOption: ProductOption | null,
  ) => {
    if (purchaseOption === null) {
      cleanValues();
    } else {
      const selectedProduct = products.find(
        (product) => product.id === purchaseOption.value,
      );
      setProductSelected(selectedProduct || undefined);

      if (selectedProduct) {
        setProductForm({
          ...productForm,
          name: selectedProduct.name,
          price: selectedProduct.price,
          description: selectedProduct.description || '',
          image: selectedProduct.image,
          purchaseOptions: selectedProduct.purchaseOptions,
          boxWeight: selectedProduct.boxWeight || '',
        });

        if (typeof selectedProduct.purchaseOptions === 'string') {
          const purchaseOptionsMap = purchaseOptions.reduce((acc, option) => {
            acc[option.value] = option.label;
            return acc;
          }, {} as { [key: string]: string });

          const selectedPurchaseOptions = selectedProduct.purchaseOptions
            .split(',')
            .map((key) => ({
              value: key.trim(),
              label: purchaseOptionsMap[key.trim()] || key.trim(),
            }));

          setSelectedPurchaseOptions(selectedPurchaseOptions);
        }
      }
    }
  };

  useEffect(() => {
    if (file) {
      const load = async () => {
        try {
          const image = await readFile(file);
          setProductForm((prev) => ({ ...prev, image }));
        } catch (error) {
          console.error('Error reading file:', error);
        }
      };
      load();
    }
  }, [file]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setProductForm((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleInputBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name } = e.target;
    setBlured((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        // Configuración de compresión
        const options = {
          maxSizeMB: 0.5, // Tamaño máximo en MB
          maxWidthOrHeight: 500, // Ancho o alto máximo en px
          useWebWorker: true,
        };

        // Comprimir la imagen
        const compressedFile = await imageCompression(file, options);

        // Convertir a base64
        const imageBase64 = await readFile(compressedFile);
        setProductForm((prev) => ({ ...prev, image: imageBase64 }));
      } catch (error) {
        console.error('Error al comprimir la imagen:', error);
      }
    }
  };

  const handleRemovePhoto = () => {
    setProductForm((prev) => ({ ...prev, image: '' }));
  };

  const saveProduct = async () => {
    if (!selectedPurchaseOptions || selectedPurchaseOptions.length === 0) {
      toast.error('Debes seleccionar al menos una opción de compra.', {
        duration: 3000,
      });
      return;
    }

    const payload = {
      ...productForm,
      purchaseOptions: selectedPurchaseOptions.map(
        (purchaseOption) => purchaseOption.value,
      ),
    };

    try {
      showLoading();
      console.log('saved token', savedToken);
      const url = `${HOST}/products`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${savedToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al crear el producto');
      }

      const product: Product = await response.json();
      addProduct(product);
      toast.success(`Se creó el producto ${product.name}!`, { duration: 4000 });
    } catch (error) {
      console.error('Error:', error);
      toast.error(`Error al crear el producto! ${error.message}`, {
        duration: 4000,
      });
    } finally {
      hideLoading();
    }
  };

  const updateProductForm = async () => {
    if (!productSelected) return;

    if (!selectedPurchaseOptions || selectedPurchaseOptions.length === 0) {
      toast.error('Debes seleccionar al menos una opción de compra.', {
        duration: 3000,
      });
      return;
    }

    const payload = {
      ...productForm,
      purchaseOptions: selectedPurchaseOptions.map(
        (purchaseOption) => purchaseOption.value,
      ),
    };

    try {
      showLoading();
      const url = `${HOST}/products/${productSelected.id}`;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${savedToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error('Error al actualizar el producto');

      const updatedProduct = await response.json();
      toast.success(
        `Producto "${updatedProduct.name}" actualizado con éxito!`,
        { duration: 4000 },
      );
      cleanValues();
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al actualizar el producto', { duration: 4000 });
    } finally {
      hideLoading();
    }
  };

  const deleteProductForm = async () => {
    if (!productSelected) return;

    try {
      const url = `${HOST}/products/${productSelected.id}`;
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${savedToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Failed to delete product');

      toast.success(
        `El Producto "${productSelected.name}" eliminado con éxito!`,
        {
          duration: 4000,
        },
      );
      setProductSelected(undefined);
      deleteProduct(productSelected.id);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al eliminar el producto', { duration: 4000 });
    }
  };

  const handleProduct = async () => {
    if (
      !productForm.name ||
      !productForm.price ||
      !productForm.image ||
      !productForm.boxWeight
    ) {
      toast.error(
        'Por favor, completa los campos de nombre, precio, peso e imagen.',
        {
          duration: 4000,
        },
      );
      return;
    }

    if (productSelected?.id) {
      updateProductForm();
    } else {
      saveProduct();
    }
    cleanValues();
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleDeleteProduct = () => {
    if (productSelected?.id) {
      deleteProductForm();
      cleanValues();
      setIsOpenConfirmModal(false);
    }
  };

  const handleImageURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setProductForm((prev) => ({ ...prev, image: url }));
  };

  return (
    <div className="modal">
      {isOpenConfirmModal && (
        <ConfirmModal
          confirmText={`${
            productSelected?.id ? 'Eliminar' : 'Agregar'
          } producto`}
          bodyText={`Estas seguro de eliminar el producto ${productForm.name}?`}
          cancel={() => setIsOpenConfirmModal(false)}
          action={handleDeleteProduct}
        />
      )}
      <div>
        <Header />
        <div className="margin-top-80">
          <h4 className="title">
            {productSelected?.id
              ? `Actualizar el producto "${productSelected.name}"`
              : 'Crear un nuevo Producto'}
          </h4>
          <div className="flex align-center">
            <Select
              className="full-width"
              options={productOptions}
              value={
                productSelected
                  ? { value: productSelected.id, label: productSelected.name }
                  : null
              }
              onChange={handleChangeSelectedProduct}
              placeholder="Selecciona un producto"
              isClearable
            />
            <button
              type="button"
              className="button button-remove"
              onClick={() => setIsOpenConfirmModal(true)}
              disabled={!productSelected}
            >
              <FaRegTrashAlt />
            </button>
          </div>

          {!isLoading && (
            <form className="flex-column">
              <div className="flex-align-center">
                <label className="label-product-form">Nombre:</label>
                <input
                  name="name"
                  className="input"
                  placeholder="Nombre producto"
                  value={productForm.name}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />
              </div>

              <div className="flex-align-center">
                <label className="label-product-form">Precio:</label>
                <input
                  name="price"
                  className="input"
                  placeholder="Precio"
                  value={productForm.price}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />
              </div>

              <div className="flex-align-center">
                <label className="label-product-form">Peso:</label>
                <input
                  name="boxWeight"
                  className="input"
                  placeholder="Peso"
                  value={productForm.boxWeight}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />
              </div>

              <div className="flex-align-center">
                <label className="label-product-form">
                  Opciones de compra:
                </label>
                <Select
                  isMulti
                  placeholder="Selecciona las opciones de compra"
                  options={purchaseOptions}
                  value={selectedPurchaseOptions}
                  onChange={handlePurchaseOptionsChange}
                />
              </div>

              <div className="flex">
                <label className="label-product-form">Descripción:</label>
                <textarea
                  name="description"
                  ref={textareaRef}
                  className="textarea"
                  placeholder="Descripción del producto ..."
                  value={productForm.description}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />
              </div>

              <div className="flex-align-center">
                <label htmlFor="imageURL" className="label-product-form">
                  URL de la Imagen:
                </label>
                <input
                  type="text"
                  name="imageURL"
                  className="input"
                  placeholder="Ingrese la URL de la imagen"
                  onChange={handleImageURLChange}
                />
              </div>

              <div className="input-container">
                <div className="flex align-center margin-top">
                  <label htmlFor="image-upload" className="label-product-form">
                    Foto:
                  </label>
                  <input
                    name="image"
                    type="file"
                    ref={fileInputRef}
                    hidden
                    onChange={handleFileChange}
                  />
                  <div className="flex border-image">
                    <img
                      className="avatar-form"
                      src={productForm.image || placeholderPicture}
                      alt="Product"
                    />
                    <button
                      className="button-upload"
                      type="button"
                      onClick={handleButtonClick}
                    >
                      Cargar imagen
                      <MdAttachFile />
                    </button>
                  </div>
                </div>
              </div>

              <div className="margin-top-30">
                <button
                  type="button"
                  className="primary-button"
                  onClick={handleProduct}
                >
                  {productSelected?.id ? 'Actualizar' : 'Agregar'} producto
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
