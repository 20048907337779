import React, { useEffect, useRef, useState } from 'react';
import { TiThMenu } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../user/context/UserContext.tsx';
import { ROUTE_NAME_OPTIONS } from '../../utils/constants.ts';
import { isLogged } from '../../utils/utils.ts';
import Button from '../button/Button.tsx';
import './dropdown.scss';

interface DropdownProps {
  title?: string;
  items: Routes[];
}

interface Routes {
  label: string;
  routeName: string;
}

const Dropdown: React.FC<DropdownProps> = ({ title, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const { logout } = useUserContext();

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNavigate = (routeName: string) => {
    navigate(`/${routeName}`);
  };

  const handleLogout = () => {
    if (isLogged()) {
      logout();
    }
    navigate(`/`);
  };

  const handleLogin = () => {
    navigate(`/${ROUTE_NAME_OPTIONS.LOGIN}`);
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <Button className="button margin-responsive" onClick={toggleDropdown}>
        <TiThMenu size={20} />
      </Button>
      {isOpen && (
        <div className="dropdown-menu">
          {items.map((item, index) => (
            <div
              key={index}
              onClick={() => handleNavigate(item.routeName)}
              className="dropdown-item"
            >
              {item.label}
            </div>
          ))}
          <div
            onClick={() => (isLogged() ? handleLogout() : handleLogin())}
            className="dropdown-item"
          >
            {isLogged() ? 'Salir' : 'Ingresa'}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
