import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Product, ProductOptionValues } from '../ProductType.ts';
import { HOST, ORDER_STATUS } from '../../utils/constants.ts';
import { User } from '../../user/context/UserContext.tsx';
import { isLogged } from '../../utils/utils.ts';

export interface CartItem {
  id: string;
  product: Product;
  quantity: number;
  partialPrice: number;
  totalPrice: number;
  purchaseOption: ProductOptionValues;
}

export interface ShoppingCart {
  items: CartItem[];
  totalItems: number;
  totalPrice: number;
}

export interface Order {
  id: string;
  user: User;
  date: Date;
  cart: ShoppingCart;
  startReceiveOrderTime: string;
  endReceiveOrderTime: string;
  status: ORDER_STATUS;
}

interface ProductContextProps {
  products: Product[];
  addProduct: (product: Product) => void;
  updateProduct: (updatedProduct: Product) => void;
  deleteProduct: (id: string) => void;
  filteredProducts: Product[];
  searchProduct: string;
  setSearchProduct: (search: string) => void;
  shoppingCart: ShoppingCart;
  addToCart: (cartItem: CartItem) => void;
  deleteFromCart: (id: string, purchaseOption: string) => void;
  createOrder: (orderData: Order) => Promise<any>;
  order?: Order;
  setProducts: (products: Product[]) => void;
  setShoppingCart: (shoppingCart: ShoppingCart) => void;
}

export const initialShoppingCart: ShoppingCart = {
  items: [],
  totalItems: 0,
  totalPrice: 0,
};

const ProductContext = createContext<ProductContextProps | undefined>(
  undefined,
);

interface ProductProviderProps {
  children: ReactNode;
}

export const ProductProvider: React.FC<ProductProviderProps> = ({
  children,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [searchProduct, setSearchProduct] = useState<string>('');
  const [shoppingCart, setShoppingCart] = useState<ShoppingCart>(() => {
    const savedCart = localStorage.getItem('shoppingCart');
    return savedCart ? JSON.parse(savedCart) : initialShoppingCart;
  });
  const [order, setOrder] = useState<Order | undefined>(undefined);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (!isLogged()) return;

        const response = await fetch(`${HOST}/products`, {
          headers: {
            Authorization: `Bearer ${isLogged()}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const createOrder = async (orderData: Order) => {
    try {
      const response = await fetch('/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        throw new Error('Error al crear el pedido');
      }

      return await response.json();
    } catch (error) {
      console.error('Error al crear el pedido:', error);
      throw error;
    }
  };

  useEffect(() => {
    setFilteredProducts(
      products.filter((product) =>
        product.name.toLowerCase().includes(searchProduct.toLowerCase()),
      ),
    );
  }, [searchProduct, products]);

  const addProduct = (product: Product) => {
    setProducts((prevProducts) => [...prevProducts, { ...product }]);
  };

  const updateProduct = (updatedProduct: Product) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product,
      ),
    );
  };

  const deleteProduct = (id: string) => {
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== id),
    );
  };

  const deleteFromCart = (id: string, purchaseOption: string) => {
    setShoppingCart((prevCart) => {
      const updatedItems = prevCart.items.filter(
        (item) =>
          !(item.product.id === id && item.purchaseOption === purchaseOption),
      );

      const totalItems = updatedItems.reduce(
        (acc, item) => acc + item.quantity,
        0,
      );

      const totalPrice = updatedItems.reduce(
        (acc, item) => acc + parseFloat(item.product.price) * item.quantity,
        0,
      );

      const updatedCart = {
        items: updatedItems,
        totalItems,
        totalPrice,
      };

      localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));

      return updatedCart;
    });
  };

  const addToCart = (cartItem: CartItem) => {
    setShoppingCart((prevCart) => {
      const existingItemIndex = prevCart.items.findIndex(
        (item) =>
          item.product.id === cartItem.product.id &&
          item.purchaseOption === cartItem.purchaseOption,
      );

      let updatedItems;

      if (existingItemIndex >= 0) {
        // Si el producto ya existe en el carrito, actualizamos su cantidad y recalculamos partialPrice
        updatedItems = prevCart.items.map((item, index) =>
          index === existingItemIndex
            ? {
                ...item,
                quantity: item.quantity + cartItem.quantity,
                partialPrice:
                  cartItem.purchaseOption === ProductOptionValues.HALF_CRATE
                    ? parseFloat(item.product.price) / 2
                    : parseFloat(item.product.price),
              }
            : item,
        );
      } else {
        // Si el producto no está en el carrito, asignamos partialPrice al agregarlo
        cartItem.partialPrice =
          cartItem.purchaseOption === ProductOptionValues.HALF_CRATE
            ? parseFloat(cartItem.product.price) / 2
            : parseFloat(cartItem.product.price);

        updatedItems = [...prevCart.items, cartItem];
      }

      const totalItems = updatedItems.reduce(
        (acc, item) => acc + item.quantity,
        0,
      );
      const totalPrice = updatedItems.reduce(
        (acc, item) => acc + item.partialPrice * item.quantity,
        0,
      );

      const updatedCart = {
        items: updatedItems,
        totalItems,
        totalPrice,
      };

      localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));

      return updatedCart;
    });
  };

  return (
    <ProductContext.Provider
      value={{
        products,
        addProduct,
        updateProduct,
        deleteProduct,
        filteredProducts,
        searchProduct,
        setSearchProduct,
        shoppingCart,
        addToCart,
        deleteFromCart,
        createOrder,
        order,
        setProducts,
        setShoppingCart,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useProductContext must be used within a ProductProvider');
  }
  return context;
};
