import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import './footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__contact">
          <h3>Contacto</h3>
          <p>+54 9 11 4049-0058</p>
          <p>rapifruticonsultas@gmail.com</p>
        </div>

        <div className="footer__social">
          <h3>Síguenos en</h3>
          <div className="flex-align-justify-center">
            <FaInstagram />
            <a
              href="https://www.instagram.com/rapifruti/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__instagram-link"
            >
              Instagram
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
