import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/loading/Loading.tsx';
import { UserInfo, useUserContext } from '../user/context/UserContext.tsx';
import { HOST } from '../utils/constants.ts';
import { useLoadingContext } from './context/LoadingContext.tsx';
import './login.scss';

type LoginForm = {
  email: string;
  password: string;
};

type RegisterForm = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  address: string;
  city: string;
  phone: string;
  description: string;
};

const PasswordInput = ({
  id,
  placeholder,
  value,
  onChange,
}: {
  id: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div style={{ position: 'relative', marginBottom: '15px' }}>
      <input
        id={id}
        type={isPasswordVisible ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={{
          ...styles.inputButton,
          paddingRight: '40px',
        }}
      />
      <span
        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        style={{
          display: 'flex',
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
        }}
      >
        {isPasswordVisible ? (
          <FaEye style={{ color: '#333', fontSize: '1.2rem' }} />
        ) : (
          <FaEyeSlash style={{ color: '#333', fontSize: '1.2rem' }} />
        )}
      </span>
    </div>
  );
};

const Login: React.FC = () => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [loginForm, setLoginForm] = useState<LoginForm>({
    email: '',
    password: '',
  });
  const [registerForm, setRegisterForm] = useState<RegisterForm>({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    address: '',
    city: '',
    phone: '',
    description: '',
  });

  const { showLoading, hideLoading, isLoading } = useLoadingContext();

  const { setUserInfo } = useUserContext();
  const navigate = useNavigate();

  const login = async () => {
    const url = `${HOST}/auth/login`;
    try {
      showLoading();
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginForm),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Login failed');
      }

      const userInfo: UserInfo = await response.json();

      // Verificar si el usuario está verificado
      if (!userInfo.user.isVerified) {
        toast.error('Debes verificar tu cuenta antes de iniciar sesión.', {
          duration: 4000,
        });
        return; // Salir si no está verificado
      }

      setUserInfo(userInfo);
      localStorage.setItem('token', userInfo.token);
      localStorage.setItem('userInfo', JSON.stringify(userInfo.user));
      navigate(`/`);
    } catch (error) {
      toast.error(`Error al intentar loguearse, ${error.message}`, {
        duration: 4000,
      });
    } finally {
      hideLoading();
    }
  };

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault();
    login();
  };

  const handleRegister = () => {
    setIsRegistering(true);
  };

  const register = async () => {
    showLoading();
    const url = `${HOST}/auth/register`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(registerForm),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al registrarse');
      }
      toast.success(
        <div>
          Se envió un mail con el link de activación de tu cuenta, revisa tu
          carpeta de <strong>spam</strong>, en caso de que no te aparezca.
        </div>,
        { duration: 10000 },
      );

      setIsRegistering(false);
    } catch (error) {
      toast.error(`Error al registrarse, ${error.message}`, {
        duration: 4000,
      });
    } finally {
      hideLoading();
    }
  };

  const validateArgentinianPhone = (phone) => {
    const cleanedPhone = phone.replace(/[\s()-]/g, ''); // Eliminar espacios, paréntesis y guiones
    const regex = /^(\+?54)\d{10}$/; // El + es opcional
    return regex.test(cleanedPhone);
  };

  const handleRegisterSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Verificar que las contraseñas coincidan
    if (registerForm.password.length < 8) {
      toast.error('La contraseña debe tener al menos 8 caracteres.');
      return;
    }
    if (registerForm.password !== registerForm.confirmPassword) {
      toast.error('Las contraseñas no coinciden');
      return;
    } else {
      if (!validateArgentinianPhone(registerForm.phone)) {
        toast.error(
          'Por favor, ingresa un número de teléfono válido de Argentina.',
        );
      } else {
        register();
      }
    }
  };

  return (
    <>
      <h1>{isRegistering ? 'Registro' : 'Login'}</h1>
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={isRegistering ? handleRegisterSubmit : handleLogin}>
          {isRegistering ? (
            <>
              <label style={styles.label} htmlFor="email">
                Correo electrónico
              </label>
              <input
                id="email"
                type="email"
                placeholder="Email"
                required
                value={registerForm.email}
                onChange={(e) =>
                  setRegisterForm({ ...registerForm, email: e.target.value })
                }
                style={styles.inputButton}
              />

              <label style={styles.label} htmlFor="password">
                Contraseña
              </label>
              <PasswordInput
                id="password"
                placeholder="Contraseña"
                value={registerForm.password}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    password: e.target.value,
                  })
                }
              />
              <span style={styles.helperText}>minimo 8 caracteres.</span>

              <label style={styles.label} htmlFor="confirmPassword">
                Repetir Contraseña
              </label>
              <PasswordInput
                id="confirmPassword"
                placeholder="Repetir Contraseña"
                value={registerForm.confirmPassword}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    confirmPassword: e.target.value,
                  })
                }
              />
              <label style={styles.label} htmlFor="name">
                Nombre completo
              </label>
              <input
                id="name"
                type="text"
                placeholder="Nombre completo"
                value={registerForm.name}
                onChange={(e) =>
                  setRegisterForm({ ...registerForm, name: e.target.value })
                }
                style={styles.inputButton}
                required
              />
              <label style={styles.label} htmlFor="address">
                Dirección
              </label>
              <input
                id="address"
                type="text"
                placeholder="Dirección"
                value={registerForm.address}
                onChange={(e) =>
                  setRegisterForm({ ...registerForm, address: e.target.value })
                }
                style={styles.inputButton}
                required
              />
              <label style={styles.label} htmlFor="city">
                Ciudad
              </label>
              <input
                id="city"
                type="text"
                placeholder="Ciudad"
                value={registerForm.city}
                onChange={(e) =>
                  setRegisterForm({ ...registerForm, city: e.target.value })
                }
                style={styles.inputButton}
                required
              />
              <label style={styles.label} htmlFor="phone">
                Teléfono
              </label>
              <PhoneInput
                placeholder="Ingresa tu número de teléfono"
                value={registerForm.phone || '+54 (11)'}
                onChange={(e) => setRegisterForm({ ...registerForm, phone: e })}
                country={'ar'}
                enableAreaCodes={true}
                enableLongNumbers={true}
                preferredCountries={['ar']}
                inputProps={{
                  required: true,
                }}
              />
              <div style={styles.margintop15}>
                <button type="submit" className="primary-button">
                  Registrarse
                </button>
              </div>
            </>
          ) : (
            <>
              <label style={styles.label} htmlFor="loginEmail">
                Correo electrónico
              </label>
              <input
                id="loginEmail"
                type="email"
                placeholder="Email"
                required
                value={loginForm.email}
                onChange={(e) =>
                  setLoginForm({ ...loginForm, email: e.target.value })
                }
                style={styles.inputButton}
              />
              <label style={styles.label} htmlFor="loginPassword">
                Contraseña
              </label>
              <input
                id="loginPassword"
                type="password"
                placeholder="Contraseña"
                required
                value={loginForm.password}
                onChange={(e) =>
                  setLoginForm({ ...loginForm, password: e.target.value })
                }
                style={styles.inputButton}
              />
              <div className="margin-top">
                <button type="submit" className="primary-button">
                  Iniciar Sesión
                </button>
              </div>
            </>
          )}

          <p>
            {isRegistering ? (
              <>
                <span>¿Ya tienes cuenta? </span>
                <span
                  onClick={() => setIsRegistering(false)}
                  className="text-link"
                >
                  Inicia sesión
                </span>
              </>
            ) : (
              <>
                <span>¿No tienes cuenta? </span>
                <span onClick={handleRegister} className="text-link">
                  Regístrate
                </span>
              </>
            )}
          </p>
        </form>
      )}
    </>
  );
};

export default Login;

const styles = {
  heading: { textAlign: 'center' },
  inputButton: {
    display: 'block',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '-webkit-fill-available',
  },
  button: {
    backgroundColor: '#4CAF50',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  label: {
    fontWeight: 500,
  },
  helperText: {
    fontSize: '0.9rem',
    color: '#666',
    marginTop: '-8px',
    marginBottom: '10px',
    display: 'block',
  },
  margintop15: {
    marginTop: '15px',
  },
};
