import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineClear } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IoToday } from 'react-icons/io5';
import Select from 'react-select';
import { useDebouncedCallback } from 'use-debounce';
import Button from '../components/button/Button.tsx';
import Header from '../components/header/Header.tsx';
import Loading from '../components/loading/Loading.tsx';
import ConfirmModal from '../components/modal/ConfirmModal.tsx';
import { useLoadingContext } from '../login/context/LoadingContext.tsx';
import { Order } from '../product/context/ProductContext.tsx';
import { ProductOptionValues } from '../product/ProductType.ts';
import { useUserContext } from '../user/context/UserContext.tsx';
import { HOST, optionsStatus, ORDER_STATUS } from '../utils/constants.ts';
import CartItemList from './cartItem/CartItemList.tsx';
import ExportOrdersButton from './ExportOrdersButton.tsx';
import './orders.scss';

function Orders() {
  const [openOrderId, setOpenOrderId] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<ORDER_STATUS>();
  const [searchText, setSearchText] = useState<string>('');
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [allOrders, setAllOrders] = useState<Order[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newStatus, setNewStatus] = useState<string>('');
  const { userInfo } = useUserContext();

  const { showLoading, hideLoading, isLoading } = useLoadingContext();
  const isUser = !userInfo?.user?.isAdmin;
  const savedToken = localStorage.getItem('token');

  useEffect(() => {
    if (userInfo?.user) {
      if (isUser) {
        getUserLatestOrders();
      } else {
        getAllOrdersFromThisDay();
      }
    }
  }, [userInfo, isUser]);

  const getAllOrdersFromThisDay = async () => {
    try {
      const response = await fetch(`${HOST}/orders/admin-orders/today`, {
        headers: {
          Authorization: `Bearer ${savedToken}`,
        },
      });
      const orders = await response.json();
      setAllOrders(orders);
      setFilteredOrders(orders);
    } catch (error) {
      throw error;
    }
  };

  const getUserLatestOrders = async () => {
    try {
      const response = await fetch(
        `${HOST}/orders/latest/${userInfo?.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${savedToken}`,
          },
        },
      );
      if (response.ok) {
        const orders = await response.json();
        setAllOrders(orders);
        setFilteredOrders(orders);
      } else {
        throw Error('error');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Hubo un problema al obtener los pedidos');
    }
  };

  const toggleAccordion = (orderId) => {
    setOpenOrderId((prevOrderId) => (prevOrderId === orderId ? null : orderId));
  };

  const toggleOrderSelection = (orderId: string) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId],
    );
  };

  const applyNewStatus = async () => {
    try {
      showLoading();
      const response = await fetch(`${HOST}/orders/update-multiple-statuses`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${savedToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderIds: selectedOrders, newStatus }),
      });
      if (response.ok) {
        toast.success('Estado actualizado correctamente');
        setShowModal(false);
        setSelectedOrders([]);
        getAllOrdersFromThisDay();
      } else {
        throw new Error('Error al actualizar el estado');
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Hubo un problema al actualizar el estado');
    } finally {
      hideLoading();
    }
  };

  const timeAwaitToSearch: number = 500;

  const debounceSearch = useDebouncedCallback((searchOrders) => {
    setSearchText(searchOrders);
  }, timeAwaitToSearch);

  const calculateBoxesPerProduct = (orders: Order[]) => {
    const productBoxesMap: Record<
      string,
      {
        name: string;
        totalCrates: number;
        totalKg: number;
        totalPacks: number;
        totalUnits: number;
      }
    > = {};

    orders?.forEach((order) => {
      order.cart.items.forEach((item) => {
        const { product, quantity, purchaseOption } = item;

        // Manejo según la opción de compra
        let crateCount = 0; // Default para cajas
        let kgCount = 0; // Para kilos
        let packCount = 0; // Para paquetes
        let unitCount = 0; // Para unidades

        switch (purchaseOption) {
          case ProductOptionValues.HALF_CRATE:
            crateCount = 0.5 * quantity;
            break;
          case ProductOptionValues.FULL_CRATE:
            crateCount = quantity;
            break;
          case ProductOptionValues.KG:
            kgCount = quantity; // Asumimos que "quantity" ya está en kilos
            break;
          case ProductOptionValues.PACK:
            packCount = quantity; // Se puede escalar a cajas si hay un equivalente
            break;
          case ProductOptionValues.UNIT:
            unitCount = quantity; // Asumimos que "quantity" es el número de unidades
            break;
          default:
            break;
        }

        // Inicializar el producto si no existe en el mapa
        if (!productBoxesMap[product.id]) {
          productBoxesMap[product.id] = {
            name: product.name,
            totalCrates: 0,
            totalKg: 0,
            totalPacks: 0,
            totalUnits: 0,
          };
        }

        // Sumar las cantidades según el tipo
        productBoxesMap[product.id].totalCrates += crateCount;
        productBoxesMap[product.id].totalKg += kgCount;
        productBoxesMap[product.id].totalPacks += packCount;
        productBoxesMap[product.id].totalUnits += unitCount;
      });
    });

    return Object.values(productBoxesMap);
  };

  const summary = calculateBoxesPerProduct(filteredOrders);

  function mapOrderStatus(status: string): string {
    switch (status) {
      case 'Pendiente':
        return ORDER_STATUS.PENDING;
      case 'Finalizado':
        return ORDER_STATUS.COMPLETE;
      case 'Cancelado':
        return ORDER_STATUS.CANCEL;
      default:
        return 'Estado desconocido';
    }
  }

  const clearFilters = () => {
    setStartDate('');
    setEndDate('');
    setFilterStatus(undefined);
    if (!isUser) {
      setSearchText('');
    }
  };

  const searchOrders = async () => {
    const params = new URLSearchParams();

    if (startDate) params.append('startDate', startDate);
    if (endDate) params.append('endDate', endDate);
    if (searchText) params.append('searchText', searchText);
    if (filterStatus) params.append('status', filterStatus);

    try {
      showLoading();
      const response = await fetch(
        `${HOST}/orders/search?${params.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${savedToken}`,
          },
        },
      );
      if (response.ok) {
        const orders = await response.json();
        setFilteredOrders(orders);
      } else {
        throw new Error('Error al buscar pedidos');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Hubo un problema al buscar los pedidos');
    } finally {
      hideLoading();
    }
  };

  const getStatusClass = (status: ORDER_STATUS) => {
    switch (status) {
      case ORDER_STATUS.PENDING:
        return 'label-pending';
      case ORDER_STATUS.COMPLETE:
        return 'label-completed';
      case ORDER_STATUS.CANCEL:
        return 'label-canceled';
      default:
        return '';
    }
  };

  const handleCheckbox = (e, orderId) => {
    e.stopPropagation();
    toggleOrderSelection(orderId);
  };

  return (
    <div>
      {showModal && (
        <ConfirmModal
          body={<ModalBody newStatus={newStatus} setNewStatus={setNewStatus} />}
          confirmText="Cambiar estado"
          action={() => applyNewStatus()}
          cancel={() => setShowModal(false)}
        />
      )}

      <Header />
      <div className=" margin-top-80">
        <h3 className="product-title text-center">Mis Pedidos</h3>
        <div className="date-filter">
          <label className="flex-column">
            Desde
            <input
              className="input filters-width"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label className="flex-column">
            Hasta
            <input
              className="input filters-width"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
          {!isUser && (
            <label className="flex-column">
              Por nombre
              <input
                className="input filters-width"
                placeholder="busca pedidos..."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  debounceSearch(e.target.value);
                }}
              />
            </label>
          )}
          <label className="flex-column">
            Por estado
            <Select
              placeholder="estados"
              value={optionsStatus.find(
                (option) => option.value === filterStatus,
              )}
              options={optionsStatus}
              onChange={(selectedOption) =>
                setFilterStatus(selectedOption?.value || undefined)
              }
            />
          </label>

          <Button
            tooltip="Buscar"
            className="button-cleanFilters margin-top-22"
            onClick={searchOrders}
          >
            <BsSearch />
          </Button>
          <Button
            tooltip="Limpiar Filtros"
            className="button-cleanFilters margin-top-22"
            onClick={clearFilters}
          >
            <AiOutlineClear />
          </Button>
          <Button
            tooltip="Busquedas para hoy"
            className="button-cleanFilters margin-top-22"
            onClick={getAllOrdersFromThisDay}
          >
            <IoToday />
          </Button>
          {!isUser && (
            <>
              <button
                className="primary-button margin-top"
                onClick={() => setShowModal(true)}
                disabled={filteredOrders.length === 0}
              >
                Cambiar estado
              </button>
              <ExportOrdersButton filteredOrders={filteredOrders} />
            </>
          )}
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            {Array.isArray(filteredOrders) && filteredOrders.length > 0 ? (
              filteredOrders.map((order: Order) => (
                <div key={order.id} className="flex align-center">
                  <div className="order-accordion">
                    <button
                      className="accordion-header"
                      onClick={() => toggleAccordion(order.id)}
                    >
                      {openOrderId === order.id ? (
                        <FaChevronDown className="margin-right" />
                      ) : (
                        <FaChevronUp className="margin-right" />
                      )}
                      {order.user?.name} -{' '}
                      {new Date(order.date).toLocaleString()}
                    </button>
                    {openOrderId === order.id && (
                      <div className="accordion-content">
                        <p>
                          <strong>Estado pedido:</strong>{' '}
                          <label
                            className={`label-status ${getStatusClass(
                              order.status,
                            )}`}
                          >
                            {mapOrderStatus(order.status)}
                          </label>
                        </p>
                        <p>
                          <strong>Fecha:</strong>{' '}
                          {new Date(order.date).toLocaleDateString()}{' '}
                        </p>
                        <div className="flex">
                          <p>
                            <strong>Entrega</strong>
                          </p>
                          <p className="margin-left-5">
                            <strong> desde:</strong>{' '}
                            {new Date(
                              order.startReceiveOrderTime,
                            ).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                          </p>
                          <p className="margin-left-5">
                            <strong>hasta:</strong>{' '}
                            {new Date(
                              order.endReceiveOrderTime,
                            ).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                          </p>
                        </div>

                        <CartItemList items={order.cart.items} />
                      </div>
                    )}
                  </div>
                  {!isUser && (
                    <input
                      className="checkbox-select-status"
                      type="checkbox"
                      checked={selectedOrders.includes(order.id)}
                      onChange={(e) => handleCheckbox(e, order.id)}
                    />
                  )}
                </div>
              ))
            ) : (
              <p className="no-orders-message">No hay pedidos disponibles.</p>
            )}
            {filteredOrders.length !== 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Cajones</th>
                    <th>Kg</th>
                    <th>Paquetes</th>
                    <th>Unidad</th>
                  </tr>
                </thead>
                <tbody>
                  {summary.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.totalCrates}</td>
                      <td>{item.totalKg}</td>
                      <td>{item.totalPacks}</td>
                      <td>{item.totalUnits}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Orders;

const ModalBody = ({ newStatus, setNewStatus }) => {
  return (
    <div>
      <h3>Cambiar estado de órdenes</h3>
      <Select
        placeholder="Eligue el nuevo estado"
        value={optionsStatus.find((option) => option.value === newStatus)}
        options={optionsStatus}
        onChange={(selectedOption) => setNewStatus(selectedOption?.value)}
      />
    </div>
  );
};
