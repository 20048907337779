import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HOST, ROUTE_NAME_OPTIONS } from '../utils/constants.ts';

const Verify = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      // Realiza la solicitud al backend para verificar el token
      fetch(`${HOST}/auth/verify?token=${token}`)
        .then((response) => {
          if (response.ok) {
            alert('Cuenta verificada exitosamente');
            navigate(`/${ROUTE_NAME_OPTIONS.LOGIN}`);
          } else {
            throw new Error('El token es inválido o ha expirado');
          }
        })
        .catch((error) => {
          alert(error.message);
          navigate(`/${ROUTE_NAME_OPTIONS.LOGIN}`);
        });
    } else {
      alert('Token no encontrado');
      navigate(`/${ROUTE_NAME_OPTIONS.LOGIN}`);
    }
  }, [searchParams, navigate]);

  return <div>Verificando tu cuenta...</div>;
};

export default Verify;
