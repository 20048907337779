import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ButtonProps {
  icon?: IconProp;
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  iconClassName?: string;
  tooltip?: string;
}

const Button: React.FC<ButtonProps> = ({
  icon,
  children,
  onClick,
  className,
  iconClassName,
  tooltip,
}) => {
  return (
    <button onClick={onClick} className={className} title={tooltip}>
      {icon && <FontAwesomeIcon icon={icon} className={iconClassName} />}
      {children}
    </button>
  );
};

export default Button;
