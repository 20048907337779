import React from 'react';
import { CartItem } from '../../product/context/ProductContext.tsx';
import { isMobileDevice } from '../../utils/utils.ts';
import { getOptionLabel } from '../../product/ProductType.ts';
import { FaRegTrashAlt } from 'react-icons/fa';

interface CartItemListProps {
  items: CartItem[];
  showDeleteButton?: boolean;
  deleteFromCart?: (productId: string, purchaseOption: string) => void;
}

const CartItemList: React.FC<CartItemListProps> = ({
  items,
  showDeleteButton = false,
  deleteFromCart,
}) => (
  <>
    <div className="shopping-cart-header">
      <span className="column-label product">Producto</span>
      <span className="column-label option">Opción</span>
      <span className="column-label quantity">
        {isMobileDevice() ? 'Cant' : 'Cantidad'}
      </span>
      <span className="column-label unit-price">Precio Unitario</span>
      <span className="column-label total-price">Total</span>
      {showDeleteButton && <span className="column-label delete" />}
    </div>

    {items.map((item: CartItem) => {
      return (
        <div
          key={`${item.product.id}-${item.purchaseOption}`}
          className="shopping-cart-item"
        >
          <span className="product-name">{item.product?.name}</span>
          <span className="option-label">
            {getOptionLabel(item.purchaseOption)}
          </span>
          <span className="quantity">{item.quantity}</span>
          <span className="unit-price">{`$${item.partialPrice}`}</span>
          <span className="total-price">{`$${
            item.partialPrice * item.quantity
          }`}</span>
          {showDeleteButton && (
            <button
              onClick={() =>
                deleteFromCart(item.product.id, item.purchaseOption)
              }
            >
              <FaRegTrashAlt />
            </button>
          )}
        </div>
      );
    })}

    {/* <div className="margin-top">
      <span>{`Precio Total: $${shoppingCart.totalPrice}`}</span>
    </div> */}
  </>
);

export default CartItemList;
