import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header/Header.tsx';
import Loading from '../components/loading/Loading.tsx';
import ConfirmModal from '../components/modal/ConfirmModal.tsx';
import { useLoadingContext } from '../login/context/LoadingContext.tsx';
import CartItemList from '../order/cartItem/CartItemList.tsx';
import {
  initialShoppingCart,
  useProductContext,
} from '../product/context/ProductContext.tsx';
import { useUserContext } from '../user/context/UserContext.tsx';
import UserInfoForm from '../user/UserInfoForm.tsx';
import { cantMinumunItems, HOST } from '../utils/constants.ts';
import './shopping-cart.scss';

const ShoppingCart = () => {
  const { shoppingCart, deleteFromCart, setShoppingCart } = useProductContext();
  const { userInfo } = useUserContext();
  const { showLoading, hideLoading, isLoading } = useLoadingContext();
  const navigate = useNavigate();
  const userData =
    userInfo?.user || JSON.parse(localStorage.getItem('userInfo') || '{}');

  const [userDataForm, setUserDataForm] = useState({
    name: userData?.name || '',
    phone: userData?.phone || '',
    address: userData?.address || '',
    city: userData?.city || '',
    description: userData?.description || '',
    email: userData?.email || '',
    startReceiveOrderTime: userData.startReceiveOrderTime || '',
    endReceiveOrderTime: userData.endReceiveOrderTime || '',
  });
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [modalBodyText, setModalBodyText] = useState<string>('');

  const savedToken = localStorage.getItem('token');

  const finishOrder = async () => {
    if (shoppingCart.totalItems < 3) {
      setIsOpenConfirmModal(false);
      return toast.error(
        'La cantidad minima para realizar un pedido deben ser 4 items',
      );
    }

    if (
      !userDataForm.startReceiveOrderTime ||
      !userDataForm.endReceiveOrderTime
    ) {
      setIsOpenConfirmModal(false);
      return toast.error(
        'Por favor, complete los horarios de recepción del pedido',
      );
    }

    const orderData = {
      userId: userInfo?.user.id,
      cartData: shoppingCart,
      startReceiveOrderTime: userDataForm.startReceiveOrderTime,
      endReceiveOrderTime: userDataForm.endReceiveOrderTime,
      description: userDataForm.description,
    };

    try {
      showLoading();
      const response = await fetch(`${HOST}/orders`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${savedToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        toast.error('Hubo un error al realizar tu pedido');
        throw new Error('Error al realizar el pedido');
      }
      toast.success(
        <div>
          Tu pedido fue realizado con exito! vas a recibir un email con la
          información de tu pedido. Si quieres puedes ver tu pedido dentro de la
          seccion <strong>"Mis Pedidos"</strong>
        </div>,
        { duration: 10000 },
      );

      localStorage.removeItem('shoppingCart');
      setShoppingCart(initialShoppingCart);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
    setIsOpenConfirmModal(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setUserDataForm((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const getConfirmOrderModalBody = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 0 && currentHour < 8) {
      setModalBodyText(
        'Estas a punto de confirmar tu pedido que será entregado durante el día de HOY',
      );
    } else {
      setModalBodyText(
        'Estas a punto de confirmar tu pedido que será entregado el día de MAÑANA',
      );
    }
  };

  const handleFinishOrder = () => {
    if (
      !userDataForm.startReceiveOrderTime ||
      !userDataForm.endReceiveOrderTime
    ) {
      toast.error(
        <div>
          Debe ingresar un horario de recepcion <strong>"desde"</strong> y un
          horario <strong>hasta</strong>
        </div>,
        { duration: 6000 },
      );
    } else {
      if (
        userDataForm.startReceiveOrderTime > userDataForm.endReceiveOrderTime
      ) {
        toast.error(
          <div>
            El horario de recepcion de envios <strong>"desde"</strong>tiene que
            ser menor al <strong>hasta</strong>
          </div>,
          { duration: 6000 },
        );
      } else {
        getConfirmOrderModalBody();
        setIsOpenConfirmModal(true);
      }
    }
  };

  const goToProductList = () => {
    navigate(`/`);
  };

  const hasMinimumItems = shoppingCart.totalItems < cantMinumunItems;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isOpenConfirmModal && (
            <ConfirmModal
              confirmText={'Confirmar pedido'}
              bodyText={modalBodyText}
              action={finishOrder}
              cancel={() => setIsOpenConfirmModal(false)}
            />
          )}
          <Header />
          <div className="margin-top-80 margin-horizontal-200">
            <h2>Carrito de compras</h2>
            <div className="price-notice-position">
              <p className="price-notice">
                Los pedidos realizados después de las 7:30AM serán entregados al
                día siguiente.
              </p>
            </div>
            <div className="shopping-info-container">
              <h4 className="margin-bottom-0">Compra mínima 4 items</h4>
              <div className="flex-align-center">
                {hasMinimumItems && (
                  <h4 className="margin-right-40">
                    {`Solo te faltan ${
                      cantMinumunItems - shoppingCart.totalItems
                    } items para poder realizar tu pedido`}
                  </h4>
                )}
                <button
                  className="primary-button align-icon-text"
                  onClick={() => goToProductList()}
                >
                  <FaPlus size={20} />
                  Agregar productos
                </button>
              </div>
            </div>

            {shoppingCart.totalItems === 0 ? (
              <p className="no-products-message">
                No hay productos en el carrito todavia...
              </p>
            ) : (
              <>
                <CartItemList
                  items={shoppingCart.items}
                  showDeleteButton={true}
                  deleteFromCart={deleteFromCart}
                />

                <div className="margin-top flex-column">
                  <span>Envio: Gratis</span>
                  <span>{`Precio Total: $${shoppingCart.totalPrice}`}</span>
                </div>
              </>
            )}

            {!hasMinimumItems && (
              <>
                <h3 className="product-title margin-top-40">
                  Completa la informacion para el envío
                </h3>
                <h4 className="margin-bottom-40">
                  Si quieres editar tu informacion personal lo puedes hacer
                  desde la seccion "Mi Informacíon"
                </h4>
                <UserInfoForm
                  userInfo={userDataForm}
                  handleInputChange={handleInputChange}
                />
              </>
            )}
            <button
              className="primary-button margin-top-40 font-18"
              disabled={hasMinimumItems}
              onClick={() => handleFinishOrder()}
            >
              Realizar Pedido
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ShoppingCart;
