import React from 'react';
import { Toaster } from 'react-hot-toast';
import { FaWhatsapp } from 'react-icons/fa';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import './app.scss';
import Button from './components/button/Button.tsx';
import Footer from './components/footer/Footer.tsx';
import Loading from './components/loading/Loading.tsx';
import LoginScreen from './login/LoginScreen.tsx';
import Verify from './login/Verify.tsx';
import { useLoadingContext } from './login/context/LoadingContext.tsx';
import Orders from './order/Orders.tsx';
import ProductDetail from './product/product-detail/ProductDetail.tsx';
import ProductForm from './product/product-form/ProductForm.tsx';
import ProductList from './product/product-list/ProductList.tsx';
import ShoppingCart from './shopping-cart/ShoppingCart.tsx';
import UserInfo from './user/UserInfo.tsx';
import { useUserContext } from './user/context/UserContext.tsx';
import { ROUTE_NAME_OPTIONS } from './utils/constants.ts';

const App = () => {
  const { userInfo } = useUserContext();
  const goToWhatsApp = () => {
    window.open('https://wa.me/541140490058', '_blank', 'noopener,noreferrer');
  };

  const { isLoading } = useLoadingContext();

  return (
    <div className="main">
      <Toaster />
      {isLoading && <Loading />}
      <Router>
        <Routes>
          <Route path="/" element={<ProductList />} />
          <Route path="/verify" element={<Verify />} />

          <Route
            path={`/${ROUTE_NAME_OPTIONS.PRODUCTS_LIST}`}
            element={<ProductList />}
          />
          <Route
            path={`/${ROUTE_NAME_OPTIONS.LOGIN}`}
            element={<LoginScreen />}
          />
          <Route
            path={`/${ROUTE_NAME_OPTIONS.SHOPPING_CART}`}
            element={<ShoppingCart />}
          />
          <Route
            path={`/${ROUTE_NAME_OPTIONS.PRODUCTS_LIST}:productBrandId`}
            element={<ProductDetail />}
          />
          <Route
            path={`/${ROUTE_NAME_OPTIONS.CREATE_PRODUCT}`}
            element={
              userInfo?.user?.isAdmin ? (
                <ProductForm />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path={`/${ROUTE_NAME_OPTIONS.USER_INFO}`}
            element={<UserInfo />}
          />
          <Route path={`/${ROUTE_NAME_OPTIONS.ORDERS}`} element={<Orders />} />
          <Route path="*" element={<div>Ruta no encontrada</div>} />
        </Routes>
        <Button className="button whatsapp-icon" onClick={goToWhatsApp}>
          <FaWhatsapp />
        </Button>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
